import {
    useQuery,
    useQueryClient,
    QueryClient,
    QueryClientProvider as QueryClientProviderBase,
  } from "react-query";
  import { useEffect } from "react";
  import supabase from "./supabase";
  
  // React Query client
  const client = new QueryClient();
  
  /**** USERS ****/
  
  // Fetch user data
  // Note: This is called automatically in `auth.js` and data is merged into `auth.user`
  export function useUser(uid : string) {
    // Manage data fetching with React Query: https://react-query.tanstack.com/overview
    return useQuery(
      // Unique query key: https://react-query.tanstack.com/guides/query-keys
      ["user", { uid }],
      // Query function that fetches data
      () =>
        supabase.from("users").select(`*`).eq("id", uid).single().then(handle),
      // Only call query function if we have a `uid`
      { enabled: !!uid }
    );
  }
  
  // Fetch user data (non-hook)
  // Useful if you need to fetch data from outside of a component
  export function getUser(uid : string) {
    return supabase.from("users").select().eq("id", uid).single().then(handle);
  }
  
  // Update an existing user
  // TODO(dps): write type for data
  export async function updateUser(uid: string, data: any) {
    const response = await supabase
      .from("users")
      .update(data)
      .eq("id", uid)
      .then(handle);
    // Invalidate and refetch queries that could have old data
    await client.invalidateQueries(["user", { uid }]);
    return response;
  }

  
  export function checkAllowlist(token : string) {
    return supabase.from("allowlist").select().eq("token", token).single().then(
      (response) => {
        if (response.error) {
          return false;
        }
        return true;
      }
    )
  }

  /**** ITEMS ****/
  /* Example query functions (modify to your needs) */
  
  // Fetch item data
  export function useItem(id : string) {
    return useQuery(
      ["item", { id }],
      () => supabase.from("items").select().eq("id", id).single().then(handle),
      { enabled: !!id }
    );
  }
  
  // Fetch all items by owner
  export function useItemsByOwner(owner : string) {
    const queryClient = useQueryClient();
  
    const queryKey = ["items", { owner }];
  
    // Fetch the items using React Query
    const itemsQuery = useQuery(
      queryKey,
      () =>
        Promise.resolve(supabase
          .from("items")
          .select()
          .eq("owner", owner)
          .order("createdAt", { ascending: false })
          .then((res) => res.data)),
      { enabled: !!owner }
    );
  
    useEffect(() => {
      if (!owner) return;
  
      // Setup real-time subscription for the items table
      const subscription = supabase
        .channel("public:items")
        .on(
          "postgres_changes",
          {
            event: "*",
            schema: "public",
            table: "items",
            filter: `owner=eq.${owner}`,
          },
          (payload) => {
            // Invalidate and refetch the query when a change is detected
            queryClient.invalidateQueries(queryKey);
          }
        )
        .subscribe();
  
      // Cleanup subscription on unmount
      return () => {
        supabase.removeChannel(subscription);
      };
    }, [owner, queryClient]);
  
    return itemsQuery;
  }
  
  // Create a new item
  export async function createItem(data:any) {
    console.log("createItem")
    const response = await supabase.from("items").insert([data]).then(handle);
    // Invalidate and refetch queries that could have old data
    await client.invalidateQueries(["items"]);
    return response;
  }
  
  // Update an item
  export async function updateItem(id:string, data:any) {
    const response = await supabase
      .from("items")
      .update(data)
      .eq("id", id)
      .then(handle);
    // Invalidate and refetch queries that could have old data
    await Promise.all([
      client.invalidateQueries(["item", { id }]),
      client.invalidateQueries(["items"]),
    ]);
    return response;
  }
  
  // Delete an item
  export async function deleteItem(id:string) {
    const response = await supabase
      .from("items")
      .delete()
      .eq("id", id)
      .then(handle);
    // Invalidate and refetch queries that could have old data
    await Promise.all([
      client.invalidateQueries(["item", { id }]),
      client.invalidateQueries(["items"]),
    ]);
    return response;
  }

  export function useRemindersByOwner(owner : string) {
    const queryClient = useQueryClient();
  
    const queryKey = ["reminders", { owner }];
  
    // Fetch the items using React Query
    const remindersQuery = useQuery(
      queryKey,
      () =>
        Promise.resolve(supabase
          .from("reminders")
          .select()
          .eq("owner", owner)
          .order("deliver_at", { ascending: false })
          .then((res) => res.data)),
      { enabled: !!owner }
    );
  
    useEffect(() => {
      if (!owner) return;
  
      // Setup real-time subscription for the items table
      const subscription = supabase
        .channel("public:reminders")
        .on(
          "postgres_changes",
          {
            event: "*",
            schema: "public",
            table: "reminders",
            filter: `owner=eq.${owner}`,
          },
          (payload) => {
            // Invalidate and refetch the query when a change is detected
            queryClient.invalidateQueries(queryKey);
          }
        )
        .subscribe();
  
      // Cleanup subscription on unmount
      return () => {
        supabase.removeChannel(subscription);
      };
    }, [owner, queryClient]);
  
    return remindersQuery;
  }

    // Delete a reminder
  export async function deleteReminder(id:string) {
    const response = await supabase
      .from("reminders")
      .delete()
      .eq("id", id)
      .then(handle);
    // Invalidate and refetch queries that could have old data
    await Promise.all([
      client.invalidateQueries(["reminder", { id }]),
      client.invalidateQueries(["reminders"]),
    ]);
    return response;
  }
  
  
  /**** HELPERS ****/
  
  // Get response data or throw error if there is one
  function handle(response:any) {
    if (response.error) throw response.error;
    return response.data;
  }
  
  // React Query context provider that wraps our app
  export function QueryClientProvider(props:any) {
    return (
      <QueryClientProviderBase client={client}>
        {props.children}
      </QueryClientProviderBase>
    );
  }
  