import Section from "./Section";
import { Link, useNavigate } from "./../util/router";
import { useAuth } from "./../util/auth";

import {
    Settings,
    LogOut,
    Sun,
    Moon
} from "lucide-react"

import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
  } from "@/components/ui/dropdown-menu"

import {useTheme} from './theme-provider';
import { Button } from "./ui/button";

import {ReactComponent as LogoSvg} from '../logo.svg'

function Navbar(props : any) {
  const auth = useAuth();
  const {theme, setTheme} = useTheme();
  const navigate = useNavigate();

  return (
    <Section bgColor={props.bgColor}>
      <div className="container py-4">
        <div className="flex justify-between">
          <Link
            to="/"
            className="inline-flex items-center space-x-2 font-bold text-lg tracking-wide text-vermillion hover:text-peach"
          >
            <LogoSvg stroke="black" fill="black" className="h-16"/>
            {/* <img src="/logo.svg" className="h-16"/> */}
            <span className="hidden lg:block">Vermillion</span>
          </Link>
          <div className="flex items-center ml-auto space-x-1 lg:space-x-4">
            <ul className="lg:flex items-center ml-auto">

              {(!auth || !auth.user) && (
                <li className="relative group text-peach">
                  <Link to="/auth/signin">
                    <span>Sign in</span>
                  </Link>
                </li>
              )}
            </ul>

            {(auth && auth.user) && (
              <DropdownMenu>
              <DropdownMenuTrigger>{auth.user.email}</DropdownMenuTrigger>
              <DropdownMenuContent className="w-56" align="end" forceMount>
                  <DropdownMenuItem onClick={() => {navigate("/dashboard")}}>Dashboard</DropdownMenuItem>
                  <DropdownMenuSeparator />
                <DropdownMenuItem onClick={() => {navigate("/settings")}}>
                    <Settings className="mr-2 h-4 w-4"/>
                    Settings
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => {auth.signout()}}>
                    <LogOut className="mr-2 h-4 w-4"></LogOut>
                    Sign out
                </DropdownMenuItem>
              </DropdownMenuContent>
              </DropdownMenu>
            )}
            <Button variant="outline" size="icon" onClick={() => {
                setTheme(theme === "dark" ? "light" : "dark")
            }}>{
                theme === "dark" ? <Moon/> : <Sun/>
            }</Button>
          </div>
        </div>
    </div>
    </Section>
  );
}

export default Navbar;
