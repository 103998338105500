import {z} from "zod";

import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"

import { Button } from "@/components/ui/button"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"

import { useAuth } from "./../util/auth";
import { useState } from "react";
import { checkAllowlist } from "@/util/db";

const formSchema = z.object({
    email: z.string().email(),
})

export function MagicLinkForm() {

    const auth = useAuth();
    const [linkSent, setLinkSent] = useState(false);
    const [notAllowlist, setNotAllowlist] = useState(false);


    const form = useForm<z.infer<typeof formSchema>>({
      resolver: zodResolver(formSchema),
      defaultValues: {
        email: "",
      },
    })
   
    function onSubmit(values: z.infer<typeof formSchema>) {
      checkAllowlist("signup_" + values.email).then((allowed) => {
        if (allowed) {
          auth!.signinWithMagicLink(values.email).then((result) => {
            console.log("ml:", result);
            if (!result.error) {
                setLinkSent(true);
            }
          })
        } else {
          setNotAllowlist(true);
        }})      
    }

    return (
        <div className="container max-w-md">
        <header className="py-8 container">
        <div className="w-full grid grid-cols-2 gap-4 content-start items-center justify-center">
          <div className="col-span-2">
            <div className="text-4xl font-bold">
              Sign up or sign in
            </div>
          </div>
        </div>
      </header>
      {notAllowlist && <h1>Sorry, no invitation found.</h1>}
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem className="p-4">
                  <FormControl>
                    <Input className='h-14 text-2xl' placeholder="you@example.com" {...field} />
                  </FormControl>
                  <FormDescription className='text-xl'>
                    {linkSent ? "📧 Link sent, check your inbox." : "🪄 We'll send a magic link to your inbox."}
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button type="submit">Send it{linkSent ? " again" : ""}</Button>
          </form>
        </Form>
        </div>
      )
  }
