import { useRouter } from "@/util/router";
import { useEffect } from "react";

function MeetPage() {
  const router = useRouter();

  const PERSON_MAP: { [key: string]: string } = {
    'dps': 'https://calendar.app.google/crz51y81AyASgWhP8',
    'nj': 'https://calendar.app.google/FEhnT79MagvNKQoT8',
    'ficus': 'https://calendar.app.google/P2MSZ9QGuzuiGA9HA',
  };
  

  useEffect(() => {
    const who = router.query.person as string;
    if (who && PERSON_MAP[who]) {
      window.location.href = PERSON_MAP[who];
    } else {
      window.location.href = "https://vermillion.ai/"
    }
  }, []);

  return (
    <div />
  );
}

export default MeetPage;
