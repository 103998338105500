import { useEffect, useState } from "react";
import Meta from "../components/Meta";
import { useRouter } from "./../util/router";
import { MagicLinkForm } from "@/components/MagicLinkForm";
import queryString from "query-string";
import { useNavigate } from "./../util/router";

function AuthPage(props: any) {
  const router = useRouter();
  const [error, setError] = useState<string | undefined>(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    const parseHash = queryString.parse(window.location.hash);
    window.lastHash = parseHash;

    // Example error URL
    // http://localhost:3000/auth/complete#error=access_denied&error_code=403&error_description=Email+link+is+invalid+or+has+expired

    if (parseHash.error) {
      setError(parseHash.error_description as string);
    }

    if (router.query.type === "complete" && !parseHash.error) {
      navigate("/dashboard");
    }
  }, [router.query.type, navigate]);

  return (
    <>
      <Meta title="Auth" />
      {router.query.type === "signin" && <MagicLinkForm />}

      {router.query.type === "complete" && error && (
        <div className="container max-w-md">
          <h1 className="text-red-600 text-2xl">
            {window.lastHash.error_description}
          </h1>
        </div>
      )}
    </>
  );
}

export default AuthPage;
