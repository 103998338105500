import React from "react";

type BackgroundImageProps = {
    image: string,
    repeat: boolean,
    opacity: number,
}
function BackgroundImage(props : BackgroundImageProps) {
  return (
    <div
      className={
        "bg-[image:var(--image)] opacity-[var(--opacity)] absolute top-0 left-0 bottom-0 right-0 z-0" +
        (props.repeat ? " bg-auto bg-left-top bg-repeat" : "") +
        (!props.repeat ? " bg-center bg-cover" : "")
      }
      style={{
        "--image": `url("${props.image}")` as string,
        "--opacity": props.opacity as number,
      } as React.CSSProperties }
    />
  );
}

export default BackgroundImage;
