import { useEffect } from "react";
import { useNavigate } from "../util/router";
import { useAuth } from "../util/auth";

import "./QuietPage.css";

function QuietPage() {
  // Is there a way to just return dumb static content for this page instead
  // of shipping the whole react bundle (while still redirecting if authed)?
  const navigate = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    if (auth?.user) {
      navigate("/dashboard", {replace: true})
    }
  }, [auth]);

  return (
    <>
      <div className="circle" id="circle1" ></div>
      <div className="circle" id="circle2" ></div>
      <div className="circle" id="circle3" ></div>
    </>
  );
}

export default QuietPage;
