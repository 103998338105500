import { useState } from "react";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";

import { ReactComponent as AudioLines } from "./audiolines.svg";

import "./WelcomePage.css";

function WelcomePage() {
  const [on, setOn] = useState(false);
  return (
    <header className="h-screen flex items-center py-8 container">
      <div className="w-full grid grid-cols-2 gap-4 content-start items-center justify-center">
        <div className="col-span-2">
          <div className="text-6xl font-bold">
            <AudioLines />
          </div>
        </div>
        <div className="col-span-2">
          <div className="flex items-center space-x-4">
            <Switch
              id="switch"
              checked={on}
              onCheckedChange={() => setOn(!on)}
            />
            <Label className="text-6xl" htmlFor="switch">
              hello,&nbsp;<span className="Animated-shimmer">{on ? "computer" : "world"}</span>
            </Label>
          </div>
        </div>
      </div>
    </header>
  );
}

export default WelcomePage;
