import { AuthProvider } from './util/auth';
import { QueryClientProvider } from './util/db';
import { Routes, Route, Router } from "./util/router";
import {ThemeProvider } from './components/theme-provider';
import Navbar from './components/Navbar';
import QuietPage from './pages/QuietPage';
import WelcomePage from './pages/WelcomePage';
import AuthPage from './pages/AuthPage';
import DashboardPage from './pages/DashboardPage';
import SettingsPage from './pages/SettingsPage';
import MeetPage from './pages/MeetPage';


function App() {
  return (
    <QueryClientProvider>
      <AuthProvider>
      <ThemeProvider>
        <Router>
          <div className="h-screen overflow-y-auto">
            <Routes>
              // Suppress the navbar for the quiet page.
              <Route path="/" element={<QuietPage/>} />
              <Route path="/meet/:person" element={<MeetPage/>} />

              <Route path="*" element={<>
                <Navbar bgColor="bg-vbg" />
                <Routes>
                  <Route path="/howdy" element={<WelcomePage/>} />
                  <Route path="/auth/:type" element={<AuthPage/>} />
                  <Route path="/dashboard" element={<DashboardPage/>} />
                  <Route path="/settings" element={<SettingsPage/>} />
                </Routes>
              </>} />
            </Routes>
          </div>
        </Router>
        </ThemeProvider>
      </AuthProvider>
    </QueryClientProvider>

  );
}

export default App;
